import React from 'react';
import styled from 'styled-components';
import Markdown from 'src/utils/markdownToComponent';
import { Grid } from '@material-ui/core';
import { colors } from 'styles';
import { Container, Content } from 'components/basics';
import { breakpoint } from 'src/utils/mediaQueries';
const ContentBlock = styled(Grid)`
  ${Content}
`;

const StyledMarkdown = styled(Markdown)`
  h1,
  h2,
  strong {
    color: ${colors.purple};
  }
  h1 {
    font-size: ${props => `${props.theme.typography.h5.fontSize}px`}
      ${breakpoint.down('md')`
     font-size: ${props => `${props.theme.typography.h6.fontSize}px`}`};
    ${breakpoint.down('sm')`
     font-size: ${props => `${props.theme.typography.body1.fontSize}px`}`}
  }
  h2 {
    padding-bottom: 1rem;
    padding-top: 2rem;
    font-size: ${props => `${props.theme.typography.h6.fontSize}px`};
    ${breakpoint.down('md')`
     font-size: ${props =>
       `${props.theme.typography.body1.fontSize}px`}; padding-top: 1rem`};
    ${breakpoint.down('sm')`
     font-size: ${props => `${props.theme.typography.subtitle2.fontSize}px`}`}
  }
  p {
    padding-bottom: 0.5rem;
    font-size: ${props => `${props.theme.typography.subtitle2.fontSize}
px`}
      ${breakpoint.down('md')`
     font-size: ${props => `${props.theme.typography.body2.fontSize}px`}`};
    ${breakpoint.down('sm')`
     font-size: ${props => `${props.theme.typography.caption.fontSize}px`}`};
     text-align: justify;
  }
  span {
    font-size: ${props => `${props.theme.typography.subtitle2.fontSize}px`};
    ${breakpoint.down('md')`
     font-size: ${props => `${props.theme.typography.body2.fontSize}px`}`};
    ${breakpoint.down('sm')`
     font-size: ${props => `${props.theme.typography.caption.fontSize}px`}`};
  }
  ul {
    padding-bottom: 1.5rem;
  }
`;

const TextBlock = ({ data }) => {
  return (
    <Container>
      <ContentBlock container spacing={40} justify="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <StyledMarkdown>{data.rawMarkdownBody}</StyledMarkdown>
        </Grid>
      </ContentBlock>
    </Container>
  );
};

export default TextBlock;
