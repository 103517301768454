import React from 'react';
import Layout from 'components/layout';
import TextBlock from 'components/pages/Legal/TextBlock';

const LegalPage = props => {
  const textBlocksData = {
    markdownContent: props.data.dataBlockText,
  };
  return (
    <Layout location={props.location} seo={props.seo}>
      <TextBlock data={textBlocksData.markdownContent} />
      {props.children}
    </Layout>
  );
};

export default LegalPage;
