import React from 'react';
import { graphql } from 'gatsby';
import LegalPage from 'components/pages/LegalPage';

const seo = {
  title: 'Q°emotion – Menciones e informaciones jurídicas',
  keywords: [],
  description:
    'Descubra las emociones de sus clientes gracias a Q°emotion. Por favor, lea toda nuestra información legal antes de utilizar nuestra solución SaaS.',
};

export default props => <LegalPage {...props} seo={seo} />;

export const pageQuery = graphql`
  query {
    dataBlockText: markdownRemark(
      frontmatter: { pageType: { eq: "legalInformation" }, lang: { eq: "es" } }
    ) {
      rawMarkdownBody
    }
  }
`;
